<template>
    <div>
        <app-table
            :id="tableId"
            :options="options"
            @action="getTableAction"
        />

        <specialization-add-edit-modal
            v-if="isSpecializationAddEditModalActive"
            :table-id="tableId"
            :selected-url="selectedUrl"
            @closeModal="closeLocationAddEditModal"
        />

        <app-delete-modal
            v-if="isSpecializationDeleteModalActive"
            :modal-id="deleteModalId"
            :preloader="deleteLoader"
            @confirmed="confirmDelete"
            @cancelled="cancelDelete"
        />
    </div>
</template>

<script>
    import SpecializationTableMixin from "./SpecializationTableMixin";

    export default {
        name: 'Specialization',
        mixins: [SpecializationTableMixin]
    }
</script>
