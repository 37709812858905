import { SPECIALIZATION } from "../../Config/ApiUrl";
import { axiosGet, axiosPost } from "../../Helpers/AxiosHelper";

export default {
    mounted() {
        this.fetchIndividualSpecializationRecords();
        this.fetchSpecializations();

        this.$hub.$on('multi-create-scroll-down', () => {
            if (this.nextPageUrl) return this.fetchSpecializations(this.nextPageUrl);
        });

        this.$hub.$on('multi-create-search', this.handleMultiCreateSearch);
        this.$hub.$on('storeData', this.handleMultiCreateStoreData);

    },
    props: {
        previousData: {},
    },
    data() {
        return {
            multiCreatePaginationLoading: false,
            multiCreateSearchedValues: [],
            multiCreateList: [],
            nextPageUrl: ''
        }

    },
    methods: {
        fetchIndividualSpecializationRecords() {
            if (!this.previousData?.options?.length) return;
            axiosPost(`${SPECIALIZATION}/list`, {specialization_ids: this.previousData.options})
                .then(data => this.multiCreateList = [...this.multiCreateList, ...data.data])
                .catch(e => console.log(e))
        },
        handleMultiCreateSearch(searchTerm) {
            if (!searchTerm) {
                this.fetchIndividualSpecializationRecords();
                this.fetchSpecializations();
                return;
            }
            axiosGet(`${SPECIALIZATION}?search=${ searchTerm }`)
                .then(({data}) => {
                    this.multiCreateSearchedValues = data.data
                })
                .catch(e => console.log(e))
        },
        getUri(url) {
            const parsedUrl = new URL(url);
            const pathAndQuery = parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
            return pathAndQuery;
        },
        removeDuplicateObjects(array, property) {
            const uniqueIds = [];
            const unique = array.filter(element => {
                const isDuplicate = uniqueIds.includes(element[property]);
                if (!isDuplicate) {
                    uniqueIds.push(element[property]);
                    return true;
                }
                return false;
            });
            return unique;
        },
        async fetchSpecializations(nextPageUrl) {
            this.multiCreatePaginationLoading = true
            try {
                const res = await axiosGet(nextPageUrl ? this.getUri(nextPageUrl) : SPECIALIZATION);
                this.multiCreateList = this.removeDuplicateObjects([ ...this.multiCreateList, ...res.data.data ], 'id');
                this.nextPageUrl = res.data.next_page_url;
            } catch(e) {
                console.log(e);
            } finally {
                this.multiCreatePaginationLoading = false
            }
        },
    }
}
