<template>
    <div class="job-opening-card bg-white p-4 cursor-pointer h-100 d-flex flex-column justify-content-between" @click="handleCardClick">
        <div class="opening-card-header d-flex">
            <div class="icon bg-primary">
                <span class="text-white">{{ job.title[0].toUpperCase() }}</span>
            </div>
            <p class="lead job-title">{{job.title | truncate}}</p>
        </div>
        <div>
            <div class="badge-wrapper mt-4 mb-3">
            	<span class="custom-badge d-inline-block mr-1">
                	{{ job.type }}
            	</span>
            </div>
            <div class="location-wrapper d-flex">
                <app-icon name="map-pin" class="d-inline-block mr-2 text-primary size-17" />
                <p class="location-text">
                    {{ job.location }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
	props: ['job'],
    filters: {
        truncate(value) {
            const limit = 40;
            if (value.length < limit) return value;
            return value.substring(0, limit) + '...'
        }
    },
    methods: {
		handleCardClick() {
			window.location.assign(this.job.url);
		}
    }
}
</script>

<style lang="scss" scoped>

</style>
