<template>
	<div class="job-search-input">
    	<input class="border" type="search" name="search" :placeholder="$t('search')" :readonly="readonly" @keyup="handleChange" />
	</div>
</template>

<script>
	export default {
		name:"Search",
		props: {
			readonly: {
				type: Boolean,
				default: true
			},
			value: {
				type: String,
				required: true
			}
		},
		methods: {
			handleChange(e) {
				this.$emit('input', e.target.value);
			}
		},
	}
</script>

<style lang="scss" scoped>

</style>
