<template>
    <div class="table-wrapper">
    	<table class="table table-card mb-3 candidates-details-table">
        	<tbody>
        		<tr>
            		<td class="text-muted width-150">{{ $t('name') }}</td>
            		<td>{{ applicant.full_name }}</td>
        		</tr>
        		<tr>
            		<td class="text-muted width-150">{{ $t('email') }}</td>
            		<td>{{ applicant.email }}</td>
        		</tr>
        		<tr>
            		<td class="text-muted width-150">{{ $t('gender') }}</td>
            		<td class="text-capitalize">{{ applicant.gender }}</td>
        		</tr>
        		<tr v-if="applicant.date_of_birth">
            		<td class="text-muted width-150">{{ $t('date_of_birth') }}</td>
            		<td>{{ formatDateToLocal(applicant.date_of_birth) }}</td>
        		</tr>
        	</tbody>
    	</table>
		<question-answer
            :apply-form="applyForm"
            :answers="answers"
        />
    </div>
</template>

<script>
import {formatDateToLocal} from "../../../../Helpers/Helpers";

export default {
    name: "CandidateDetails",
    props: {
        applicant: {},
        applyForm: {},
        answers: {}
    },
    data() {
        return {
            formatDateToLocal
        }
    }
}
</script>
