<template>
    <div class="content-wrapper p-0">
        <div v-if="!checkCondition(careerPage)" class="hero position-relative py-5">
            <div class="hero__item--content container">
                <div class="d-flex justify-content-end mb-3">
                    <a :href="urlGenerator('/admin/users/login')"
                       class="btn btn-primary">{{ loggedIn ? $t('dashboard') : $t('login') }}</a>
                </div>
            </div>
        </div>
        <hero v-else
              :cover="jobPostCover"
              :icon="icon"
              :logged-in="loggedIn"
              :logo="logo">
            <template #content>
                <div v-if="pageBlocks[pageView].header" class="text-center mb-5">
                    <h1 :style="`font-size: ${titleStyle.fontSize}; font-weight: ${titleStyle.fontWeight}; letter-spacing: ${titleStyle.letterSpacing}; color: ${titleStyle.color};`"
                        class="mb-4">
                        {{ content.title }}
                    </h1>

                    <p :style="`font-size: ${subTitleStyle.fontSize}; font-weight: ${subTitleStyle.fontWeight}; letter-spacing: ${subTitleStyle.letterSpacing}; color: ${subTitleStyle.color};`"
                       class="mb-4">
                        {{ content.subtitle }}
                    </p>
                </div>
            </template>
        </hero>

        <!-- <Search v-model="searchTerm" :readonly="false" /> -->
        <div class="editor-content">
            <div class="preview-content career-page">
                <div class="preview container">
                    <!-- <template v-if="!careerPage?.job_post_settings?.content?.hero"> -->
                    <template
                        v-if="!(careerPage && careerPage.job_post_settings && careerPage.job_post_settings.content && careerPage.job_post_settings.content.hero)">
                        <div v-if="pageBlocks[pageView].logo" class="d-flex flex-column align-items-center mb-5">
                            <img :src="icon"
                                 alt=""
                                 class="candidate-viewable-icon img-fluid"
                            />
                        </div>
                        <div v-if="pageBlocks[pageView].header" class="text-center mb-5">
                            <h1 :style="`font-size: ${titleStyle.fontSize}; font-weight: ${titleStyle.fontWeight}; letter-spacing: ${titleStyle.letterSpacing}; color: ${titleStyle.color};`"
                                class="mb-4">
                                {{ content.title }}
                            </h1>

                            <p :style="`font-size: ${subTitleStyle.fontSize}; font-weight: ${subTitleStyle.fontWeight}; letter-spacing: ${subTitleStyle.letterSpacing}; color: ${subTitleStyle.color};`"
                               class="mb-4">
                                {{ content.subtitle }}
                            </p>
                        </div>
                    </template>


                    <div v-if="pageBlocks[pageView].body" class="editor-body">
                        <div v-for="(section,index) in content.bodySection"
                             class="mb-5">
                            <h5 :style="`font-size: ${headingsStyle.fontSize}; font-weight: ${headingsStyle.fontWeight}; letter-spacing: ${headingsStyle.letterSpacing}; color: ${headingsStyle.color};`">
                                {{ section.headings }}
                            </h5>
                            <div
                                :style="`font-size: ${descriptionStyle.fontSize}; font-weight: ${descriptionStyle.fontWeight}; letter-spacing: ${descriptionStyle.letterSpacing}; color: ${descriptionStyle.color};`"
                                v-html="section.description"></div>
                        </div>
                    </div>

                    <div id="jobs">
                        <h5 :style="`font-size: ${headingsStyle.fontSize}; font-weight: ${headingsStyle.fontWeight}; letter-spacing: ${headingsStyle.letterSpacing}; color: ${headingsStyle.color};`"
                            class="job-section-heading">
                            <!-- {{ $t('job_openings') }} -->
                            {{ $t('latest_jobs') }}
                        </h5>
                        <!-- <hr/> -->
                        <div class="job-openings">
                            <div class="row">
                                <div v-for="(job, index) in jobList"
                                     :class="{'col-md-6 col-xl-4' : (activePreview === 'desktop')}"
                                     class="col-12 mb-primary">
                                    <!-- <div class="job-card"> -->
                                    <!--     <a :href="job.url" class="text-size-18">{{ job.title }}</a> -->
                                    <!--     <p class="mb-0">{{ job.type }}</p> -->
                                    <!--     <div class="text-muted text-size-13 d-flex align-items-center"> -->
                                    <!--         <app-icon class="size-14 mr-2" name="map-pin"/> -->
                                    <!--         {{ job.location }} -->
                                    <!--     </div> -->
                                    <!-- </div> -->
                                    <JobOpeningCard :job="job"/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div v-if="pageBlocks[pageView].logo" class="text-center py-4"> -->
                    <!--     <img :src="logo" alt="" class="candidate-viewable-logo img-fluid d-block mx-auto"> -->
                    <!-- </div> -->

                    <div v-if="pageBlocks[pageView].footer" class="text-center py-4">
                        {{ companyText }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {urlGenerator} from "../../../Helpers/AxiosHelper";
import {PUBLIC_JOB_POST} from "../../../Config/ApiUrl";
import Hero from "../../Helpers/Editor/Hero";
import JobOpeningCard from "../../Helpers/Editor/JobOpeningCard";
import Search from "../../Helpers/Editor/Search";
import {textTruncate} from "../../../Helpers/TextHelper";
import HeroDropdown from "./HeroDropdown.vue";

export default {
    name: 'CandidateCareerPage',
    components: {Hero, JobOpeningCard, Search, HeroDropdown},
    props: {
        careerPage: {},
        jobPosts: {},
        loggedIn: {
            type: Boolean,
            default: false
        },
    },
    filters: {
        truncate(value) {
            const limit = 40;
            if (value.length < limit) return value;
            return value.substring(0, limit) + '...'
        }
    },
    data() {
        return {
            urlGenerator,
            searchTerm: '',
            textTruncate,
            viewType: 'desktop',
            activePreview: 'desktop',
            year: moment(moment.now()).format("YYYY"),
        }
    },
    computed: {
        jobList() {
            return this.jobPosts.map(item => {
                return {
                    title: item.name,
                    type: item['job_type'].name,
                    location: item['location'].address,
                    url: urlGenerator(`${PUBLIC_JOB_POST}/${item.slug}/display`)
                }
            }).filter(item =>
                item.title.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                item.type.toLowerCase().includes(this.searchTerm.toLowerCase()) ||
                item.location.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
        },
        careerPageData() {
            let data = (typeof this.careerPage === 'string') ? JSON.parse(this.careerPage) : this.careerPage;
            return data.job_post_settings ? data.job_post_settings : data;
        },
        content() {
            return this.careerPageData.content;
        },
        pageStyle() {
            return this.careerPageData.pageStyle;
        },
        pageBlocks() {
            if (this.careerPageData.pageBlocks) return this.careerPageData.pageBlocks;
            return null;
        },
        pageView() {
            return this.activePreview === 'desktop' ? 'defaultView' : 'mobileView'
        },
        titleStyle() {
            return this.pageStyle[this.pageView].find(item => item.key === 'title')
        },
        subTitleStyle() {
            return this.pageStyle[this.pageView].find(item => item.key === 'sub-title')
        },
        detailsStyle() {
            return this.pageStyle[this.pageView].find(item => item.key === 'details')
        },
        headingsStyle() {
            return this.pageStyle[this.pageView].find(item => item.key === 'headings')
        },
        descriptionStyle() {
            return this.pageStyle[this.pageView].find(item => item.key === 'description')
        },
        icon() {
            return this.careerPageData.icon ? this.careerPageData.icon : urlGenerator(window.settings.company_icon)
        },
        logo() {
            return urlGenerator(window.settings.company_logo);
        },
        jobPostCover() {
            return urlGenerator(window.settings.job_post_cover);
        },
        companyText() {
            return `${this.$t('copyright_text') + ' ' + this.year + ' ' + this.$t('by') + ' '} ${window.settings?.company_name ? window.settings.company_name : ''}`;
        }
    },
    watch: {
        viewType: {
            handler: function (type) {
                this.toggleResponsivePreview(type)
            },
            immediate: true
        }
    },
    mounted() {
        this.checkViewType();
        window.onresize = () => {
            this.checkViewType();
        }
    },
    methods: {
        checkCondition(careerPage) {
            return careerPage?.job_post_settings?.content?.hero;
        },
        checkViewType() {
            this.viewType = window.innerWidth > 575 ? 'desktop' : 'mobile';
        },
        toggleResponsivePreview(previewType) {
            let preview = $('.preview-content .preview')

            if (previewType === 'desktop') {
                this.activePreview = 'desktop';
                preview.removeClass('mobile-preview');
                preview.addClass('desktop-preview');
            } else {
                this.activePreview = 'mobile';
                preview.removeClass('desktop-preview');
                preview.addClass('mobile-preview');
            }
        }
    }
}
</script>
