<template>
    <modal
        :modal-id="modalId"
        :title="(selectedUrl || previousData) ? $editLabel('custom_field') : $addLabel('custom_field')"
        :preloader="preloader"
        :hide-cancel-button="Boolean(previousData)"
        :submit-button-text="previousData ? $t('update') : $t('save')"
        :modal-scroll="false"
        modal-size="extra-large"
        @submit="submit"
        @closeModal="closeModal">
        <template slot="body">
            <app-overlay-loader v-if="preloader"/>
            <form :class="{'loading-opacity': preloader}"
                  ref="form"
                  :data-url="selectedUrl ? selectedUrl : (contextUrl ? contextUrl : '')">
                <div class="form-group">
                    <label for="name">{{ $t('name') }}</label>
                    <app-input
                        id="name"
                        type="text"
                        :placeholder="$placeholder('custom_field', 'name')"
                        v-model="formData.name"
                        :required="true"
                    />
                </div>
                <template v-if="!previousData.is_specialization">
                    <div class="form-group">
                        <label for="type">{{ $t('type') }}</label>
                        <app-input
                            id="type"
                            type="select"
                            :list="availableInputTypes"
                            v-model="formData.type"
                            @input="handleTypeInput"
                            :required="true"
                        />
                    </div>

                    <div v-if="haveMultiOptions" class="form-group">
                        <label for="option">{{ $t('options') }}</label>
                        <div class="custom-input-group mb-2">
                            <input
                                id="option"
                                type="text"
                                class="form-control"
                                :placeholder="$t('type_option_name')"
                                v-model="newOption"
                                @keyup.enter="addOption"
                            />
                            <div class="input-group-append">
                                <button type="button" @click="addOption">
                                    <app-icon name="plus" class="size-18"/>
                                </button>
                            </div>
                        </div>
                        <div v-if="formData.options && formData.options.length && haveMultiOptions">
                            <div
                                class="default-base-color rounded d-flex align-items-center justify-content-between px-3 py-2 mb-1"
                                v-for="(option, index) in formData.options"
                                :key="`multi-option-${index}`">
                                <span>{{ option }}</span>
                                <button type="button" class="btn bg-danger padding-5" @click="deleteOption(option)">
                                    <app-icon name="trash-2" class="text-white size-12"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <label for="option">{{ $t('options') }}</label>
                    <app-input
                        id="option"
                        type="multi-create"
                        :list="multiCreateList"
                        :multiCreateSearchedValues="multiCreateSearchedValues"
                        list-value-field="name"
                        :required="true"
                        :multiCreatePaginationLoading="multiCreatePaginationLoading"
                        v-model="formData.options"
                    />
                </template>
            </form>
        </template>
    </modal>
</template>

<script>
import {ModalMixin} from '../../../Mixins/ModalMixin';
import {FormMixin} from '../../../../core/mixins/form/FormMixin';
import { SPECIALIZATION } from '../../../Config/ApiUrl';
import { axiosGet } from '../../../Helpers/AxiosHelper';
import MultiCreatePaginationMixin from '../../../Mixins/global/MultiCreatePaginationMixin';

export default {
    name: 'CustomFieldModal',
    mixins: [ModalMixin, FormMixin, MultiCreatePaginationMixin],
    props: {
        tableId: {
            type: String
        },
        contextUrl: {
            type: String
        },
        submissionType: {
            type: String,
            default: 'form'
        },
        previousData: {},
    },
    watch: {
        previousData: {
            handler: function (data) {
                if (this.submissionType !== 'form' && data) {
                    this.formData = _.cloneDeep(data)
                }
            },
            immediate: true
        },
        inputType: {
            handler: function (data) {
                if (this.haveMultiOptions) {
                    if (this.formData.options?.length < 1) this.formData.options = [];
                }
            }
        }
    },
    mounted() {
        // this.fetchIndividualSpecializationRecords();
        // this.fetchSpecializations();
        if (this.previousData.is_specialization) {
            this.formData.type = this.previousData.type;
            this.handleTypeInput();
        }
        // this.$hub.$on('multi-create-scroll-down', () => {
        //     if (this.nextPageUrl) return this.fetchSpecializations(this.nextPageUrl);
        // });
        //
        // this.$hub.$on('multi-create-search', this.handleMultiCreateSearch);
        // this.$hub.$on('storeData', this.handleMultiCreateStoreData);
    },
    data() {
        return {
            modalId: 'custom-field-modal',
            formData: {
                name: '',
                type: '',
                options: []
            },
            availableInputTypes: [
                {id: '', value: this.$chooseLabel('option'), disabled: true},
                {id: 'text', value: this.$t('text')},
                {id: 'email', value: this.$t('email')},
                {id: 'number', value: this.$t('number')},
                {id: 'textarea', value: this.$t('textarea')},
                {id: 'tel-input', value: this.$t('tel_input')},
                {id: 'date', value: this.$t('date')},
                {id: 'radio', value: this.$t('radio')},
                {id: 'checkbox', value: this.$t('checkbox')},
                {id: 'select', value: this.$t('select')},
                {id: 'dropzone', value: this.$t('Upload')},
                {id: 'multi-select', value: this.$t('multi_select')},
                this.previousData.is_specialization ? {id: 'multi-create', value: this.$t('multi_create')} : ''
            ].filter(v => v),
            selectedMultiCreateValue: [],
            newOption: '',
            // multiCreateList: [],
            // multiCreateSearchedValues: [],
            // multiCreatePaginationLoading: false,
            // nextPageUrl: ''
        }
    },
    computed: {
        haveMultiOptions() {
            return this.formData.type === 'radio' || this.formData.type === 'checkbox' || this.formData.type === 'select' || this.formData.type === 'multi-select' || this.formData.type === 'multi-create' 
        }
    },
    methods: {
        // handleMultiCreateSearch(searchTerm) {
        //     if (!searchTerm) {
        //         this.fetchIndividualSpecializationRecords();
        //         this.fetchSpecializations();
        //         return;
        //     }
        //     axiosGet(`${SPECIALIZATION}?search=${ searchTerm }`)
        //         .then(({data}) => {
        //             this.multiCreateSearchedValues = data.data
        //         })
        //         .catch(e => console.log(e))
        // },
        handleTypeInput() {
            const inputTypesWithLists = ['checkbox', 'multi-select', 'multi-create', 'radio', 'select'];
            if (!inputTypesWithLists.includes(this.formData.type)) return;

            if (!('options' in this.formData)) {
                this.formData['options'] = [] 
            };
        },
        // fetchIndividualSpecializationRecords() {
        //     if (!this.previousData.options.length) return;
        //     axiosPost(`${SPECIALIZATION}/list`, {specialization_ids: this.previousData.options})
        //         .then(data => this.multiCreateList = [...this.multiCreateList, ...data.data])
        //         .catch(e => console.log(e))
        // },
        // getUri(url) {
        //     const parsedUrl = new URL(url);
        //     const pathAndQuery = parsedUrl.pathname + parsedUrl.search + parsedUrl.hash;
        //     return pathAndQuery;
        // },
        // removeDuplicateObjects(array, property) {
        //     const uniqueIds = [];
        //     const unique = array.filter(element => {
        //         const isDuplicate = uniqueIds.includes(element[property]);
        //         if (!isDuplicate) {
        //             uniqueIds.push(element[property]);
        //             return true;
        //         }
        //         return false;
        //     });
        //     return unique;
        // },
        // async fetchSpecializations(nextPageUrl) {
        //     this.multiCreatePaginationLoading = true
        //     try {
        //         const res = await axiosGet(nextPageUrl ? this.getUri(nextPageUrl) : SPECIALIZATION);
        //         this.multiCreateList = this.removeDuplicateObjects([ ...this.multiCreateList, ...res.data.data ], 'id');
        //         this.nextPageUrl = res.data.next_page_url;
        //     } catch(e) {
        //         console.log(e);
        //     } finally {
        //         this.multiCreatePaginationLoading = false
        //     }
        // },
        addOption() {
            if (this.newOption) {
                this.formData.options.push(this.newOption);
            }

            this.newOption = '';
        },
        async handleMultiCreateStoreData(item) {
            try {
                await this.axiosPost({url: SPECIALIZATION, data: {name: item}})
            } catch(e) {
                return this.$toastr.e(this.$t('invalid_input'));
            }
            await this.fetchSpecializations();
            const newlyAddedItemId = this.multiCreateList.find(listItem => listItem.name === item)
            if (newlyAddedItemId) this.selectedMultiCreateValue.push(newlyAddedItemId.id)
        },
        deleteOption(item) {
            this.formData.options.splice(this.formData.options.indexOf(item), 1);
        },
        submit() {
            let field = {};
            // if (this.previousData.is_specialization) {
            //     return this.$emit('customFieldUpdate', {name: this.$t('specialization'), type: 'multi-create'});
            // }

            if (this.submissionType === 'form') {
                this.save(this.formData);
            } else {
                if (this.haveMultiOptions && (this.formData.options.length < 1)) {
                    this.$toastr.w(this.$t('option_should_not_be_empty'));
                    return;
                }
                if (this.formData.name && this.formData.type) {
                    field.name = this.formData.name;
                    field.type = this.formData.type;

                    if (this.formData.options && this.formData.options.length)
                        field.options = this.formData.options;

                    if (!this.previousData) this.$emit('customFieldSubmit', field);
                    else this.$emit('customFieldUpdate', field);

                    this.afterFinalResponse();
                } else this.$toastr.w(this.$t('please_fill_all_fields'));
            }  
        },
        afterSuccess(response) {
            this.$toastr.s(response.data.message);
            this.$hub.$emit(`reload-${this.tableId}`)
        },
        afterSuccessFromGetEditData(response) {
            this.formData = response.data;
            this.preloader = false;
        }
    }
}
</script>
