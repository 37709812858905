<template>
    <div :class="readonly ? 'px-5' : ''" :style="{'backgroundImage': 'url('+cover+')'}"
         class="hero position-relative py-5">
        <div class="hero__item--content container">
            <div class="d-flex align-items-center justify-content-between mb-3">
                <div class=""><img :src="logo" alt="" class="icon"></div>
                <HeroDropdown :loggedIn="loggedIn" />
                <div class="d-none align-items-center justify-content-between d-sm-flex">
                    <div class="mr-3"><a class="btn btn-secondary" href="#jobs">{{ $t('job_post') }}</a></div>
                    <div class=""><a :href="urlGenerator('/admin/users/login ')" class="btn btn-primary">{{
                            loggedIn ? $t('dashboard') : $t('login')
                        }}</a></div>
                </div>
            </div>
            <slot name="content"></slot>
            <!-- <form @submit.prevent="search" class="search"> -->
            <!--     <input class="" type="search" name="search" :placeholder="$t('search')" :readonly="readonly" v-model="searchText"> -->
            <!-- </form> -->
        </div>
    </div>
</template>

<script>

import {urlGenerator} from "../../../Helpers/AxiosHelper";
import HeroDropdown from "../../Views/CareerPage/HeroDropdown.vue";

export default {
	components: {
		HeroDropdown
	},
    props: {
        icon: {
            type: String,
        },
        logo: {
            type: String,
        },
        readonly: {
            type: Boolean,
        },
        cover: {
            type: String,
            default: ''
        },
        loggedIn: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            urlGenerator,
            searchText: ''
        }
    },
    methods: {
        search(event) {
            console.log(event)
        },
        init() {
            let content = document.querySelector('.hero__item--content')
            let cover = document.querySelector('.hero__item--cover')
            cover.style.height = content.clientHeight + 60
        }
    },
    mounted() {
        // this.init()
    }
}
</script>

<style lang="scss" scoped>
.icon {
    max-width: 140px;
    width: 100%;
}

.search {
    display: flex;
    justify-content: center;
    align-items: center;

    input {
        border: 1px solid #fff;
        background-color: #ffffffdd;
        border-radius: 25px;
        max-width: 300px;
        width: 100%;
        padding: 5px 15px;
    }
}

.hero {
    //height: 300px;
    //height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    &__item {
        &--content {
            position: relative;
        }
    }
}

</style>
