import { render, staticRenderFns } from "./CandidateDetailsModal.vue?vue&type=template&id=535a8bad&"
import script from "./CandidateDetailsModal.vue?vue&type=script&lang=js&"
export * from "./CandidateDetailsModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports