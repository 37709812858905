<template>
    <div v-if="answers.length > 0" class="accordion accordion-question" id="accordionExample">
        <div class="card" v-for="(answer, index) in answers" v-if="answer.answer">
            <div class="card-header p-3"
                 :id="`heading-${index}`">
                <button
                        class="btn btn-block text-left p-0"
                        :class="{'collapsed':visibleIndex!==index}"
                        type="button"
                        @click.prevent="visibleIndex=index"
                        data-toggle="collapse"
                        :data-target="`#collapse-answer-${index}`"
                        aria-expanded="false"
                        :aria-controls="`collapse-answer-${index}`">
                    <span class="d-inline-flex align-items-center">
                        <app-icon name="book-open" class="mr-2 size-15 text-muted"/>
                        {{ questionTitle(answer.question) }}
                    </span>
                    <app-icon name="chevron-down" class="btn-arrow text-muted"/>
                </button>
            </div>

            <div :id="`collapse-answer-${index}`"
                 class="collapse"
                 :class="{'show': true}"
                 :aria-labelledby="`collapse-answer-${index}`"
                 data-parent="#accordionExample">
                <div class="card-body p-3">
                    <template v-if="answer.application_answer_specializations.length">
                        <span v-html="answer.application_answer_specializations.map(i => i.specialization.name).join('</br>')"></span>
                    </template>
                    <template v-else>
                        {{ answer.answer }}
                    </template>

                </div>
            </div>
        </div>
    </div>
    <app-empty-data-block
            v-else
            :message="$t('no_question_answer_found')"
    />
</template>

<script>
export default {
    name: "QuestionAnswer",
    props: {
        applyForm: {},
        answers: {}
    },
    data() {
        return {
            visibleIndex: 0
        }
    },
    computed: {
        questions() {
            let question = []
            this.applyForm.forEach(item => {
                question = [...question, ...item.fields]
            })
            return question.map(el => el.name);
        },
    },
    methods: {
        nameGen(name) {
            return _.snakeCase(_.lowerCase(name));
        },
        questionTitle(ques) {
            let question = this.questions.find(item => this.nameGen(item) === ques)
            return question ? question : '';
        },
    }
}
</script>
